import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/main/Main';
import Downloads from './pages/downloads/Downloads';
import Login from './pages/login/Login';
import Listen from './pages/listen/Listen';
import "./index.css";
import Register from './pages/register/Register';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/resetPassword/ResetPassword';
import CreateTestUser from './pages/createTestUser/CreateTestUser';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/createTester" element={<CreateTestUser />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset/:token" element={<ResetPassword/>} />
      <Route path="/" element={<Main />}>
        <Route index element={<Listen />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="*" element={<h1 className="text-white">Not Found</h1>} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;