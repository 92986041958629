import React from 'react';
import { motion } from 'framer-motion';
import axiosInstance from '../../services/axiosConfig';
import './Artist.css';

const ArtistCard = ({ name, onSelect, id, isSelected }) => (
  <motion.div
    className={`artist-card shadow-md cursor-pointer ${isSelected ? 'selected' : ''}`}
    onClick={() => onSelect(id)}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="text-lg">{name}</h3>
  </motion.div>
);

const ArtistList = ({ genreId, onSelectArtist, selectedArtist }) => {
  const [artists, setArtists] = React.useState([]);

  React.useEffect(() => {
    if (genreId) {
      const fetchArtists = async () => {
        try {
          const response = await axiosInstance.get(`/api/artists/${genreId}`);
          setArtists(response.data);
        } catch (error) {
          console.error('Erro ao buscar artistas:', error);
        }
      };

      fetchArtists();
    }
  }, [genreId]);

  const handleSelectArtist = (id) => {
    onSelectArtist(id);
  };

  return (
    <div>
      <h2 className='text-center'>Seleção</h2>
      <motion.div
        className="artist-list flex flex-wrap gap-6 p-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {artists.map((artist) => (
          <ArtistCard
            key={artist.id}
            name={artist.name}
            onSelect={handleSelectArtist}
            id={artist.id}
            isSelected={artist.id === selectedArtist} // Verifica se o artista está selecionado
          />
        ))}
      </motion.div>
    </div>
  );
};

export default ArtistList;
