import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';
import logo from '../../assets/img/logo-2.png';
import { Link } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // Adiciona o estado de carregamento
    const { login } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (username === '') {
            toast.error('O campo email é obrigatório');
            return;
        }

        if (password === '') {
            toast.error('O campo senha é obrigatório');
            return;
        }

        setLoading(true); // Define o carregamento como verdadeiro quando a solicitação começar

        try {
            await login(username, password);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error('Usuário não encontrado!');
                } else if (error.response.status === 422) {
                    toast.error('Senha inválida');
                } else {
                    toast.error('Erro ao tentar fazer login. Por favor, tente novamente.');
                }
            } else {
                toast.error('Erro de conexão. Por favor, tente novamente.');
            }
        } finally {
            setLoading(false); // Define o carregamento como falso quando a solicitação terminar
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <ToastContainer theme="dark" />
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm login">
                <div className="logo-login">
                    <img src={logo} alt="logo" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-center text-white">Login</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium text-white">Email</label>
                        <input
                            id="username"
                            type="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Ex: joaosilva@gmail.com"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            autoComplete="username"
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="password" className="block text-sm font-medium text-white">Senha</label>
                        <input
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Senha"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm pr-10"
                            autoComplete="current-password"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-1 right-0 pr-3 mt-5 flex items-center"
                        >
                            {showPassword ? (
                                <EyeOffIcon className="h-5 w-5 text-gray-400" />
                            ) : (
                                <EyeIcon className="h-5 w-5 text-gray-400" />
                            )}
                        </button>
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-2 bg-green-500 text-black font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center"
                        disabled={loading} // Desativa o botão durante o carregamento
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" /> // Exibe o ícone de carregamento
                        ) : (
                            'Acessar a plataforma'
                        )}
                    </button>
                    <div className='suporte-login'>
                    <a target='_blank' rel="noreferrer" href="https://wa.me/5519997521609?text=Estou%20precisando%20de%20suporte%2C%20pode%20me%20ajudar%3F" className="text-white text-sm hover:text-green-500 underline mx-3">
                        Preciso de ajuda
                    </a>
                    <Link to="/forgot-password" className="text-white text-sm hover:text-green-500 underline mx-3">Esqueci minha senha</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
