import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const { register } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (name === '') {
            toast.error('O campo nome é obrigatório');
            return;
        }

        if (email === '') {
            toast.error('O campo email é obrigatório');
            return;
        }


        setLoading(true);

        try {
            await register(name, email, );
            toast.success('Cadastro realizado com sucesso!');
        } catch (error) {
            if (error.response) {
                toast.error('Erro ao tentar cadastrar. Por favor, tente novamente.');
            } else {
                toast.error('Erro de conexão. Por favor, tente novamente.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <ToastContainer theme="dark" />
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm register">
                <h2 className="text-2xl font-bold mb-4 text-center text-black">Cadastro</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-black">Nome</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Ex: João Silva"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-black">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Ex: joaosilva@gmail.com"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            autoComplete="username"
                        />
                    </div>
                  
                    <button
                        type="submit"
                        className="w-full px-4 py-2 bg-green-500 text-black font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center"
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Cadastrar'
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register;
