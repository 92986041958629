// src/layouts/MainLayout.jsx

import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import { AuthContext } from '../../context/AuthContext';

const Main = () => {
    const { authToken } = useContext(AuthContext);

    // Se o usuário não estiver autenticado, redirecione para a página de login
    if (!authToken) {
        return <Navigate to="/login" />;
    }

    return (
        <div className='content-page'>
            <div className='content-sidebar'>
                <Navbar />
            </div>
            <div className='content-view-page'>
                <Outlet />
            </div>
        </div>
    );
};

export default Main;
