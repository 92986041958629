import React, { createContext, useState } from 'react';
import axiosInstance from '../services/axiosConfig';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('jwtToken') || null);

    const login = async (email, password) => {
        try {
            const response = await axiosInstance.post('/auth/login', { email, password });
            const { token } = response.data;
            console.log(response.data);
            localStorage.setItem('jwtToken', token);
            setAuthToken(token);
            window.location.href = '/';  //Redirecione para a página protegida
           console.log(token)
        } catch (error) {
            throw error; // Lance o erro para ser capturado no componente Login
            
        }
    };

    const createTestUser = async ( email ) => {
        try {
            const response = await axiosInstance.post('/auth/create-test-user', { email });
           console.log(response.data);
        } catch (error) {
            throw error; // Lança o erro para ser capturado no componente Register
        }
    };

    const register = async (name, email, ) => {
        try {
            const response = await axiosInstance.post('/auth/register', { name, email, });
           console.log(response.data);
        } catch (error) {
            throw error; // Lança o erro para ser capturado no componente Register
        }
    };

    const logout = () => {
        localStorage.removeItem('jwtToken');
        setAuthToken(null);
    };

    return (
        <AuthContext.Provider value={{ authToken, login, register, createTestUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
