import React, { useState } from 'react';
import axiosInstance from '../../services/axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import logo from '../../assets/img/logo-2.png';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password === '' || confirmPassword === '') {
            toast.error('Todos os campos são obrigatórios');
            return;
        }

        if (password !== confirmPassword) {
            toast.error('As senhas não correspondem');
            return;
        }

        setLoading(true);

        try {
            await axiosInstance.post('/auth/reset-password/confirm', { token, password });
            toast.success('Senha redefinida com sucesso!');
            setTimeout(function () {
                window.location.href = '/login';
            }, 3000);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Token inválido ou expirado');
                } else {
                    toast.error('Erro ao tentar redefinir a senha. Por favor, tente novamente.');
                }
            } else {
                toast.error('Erro de conexão. Por favor, tente novamente.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <ToastContainer theme="dark" />
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm login">
                <div className="logo-login">
                    <img src={logo} alt="logo" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-center">Redefinir Senha</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="relative">
                        <label htmlFor="password" className="block text-white text-sm font-medium">Nova Senha</label>
                        <input
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Nova senha"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            autoComplete="new-password"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-1 right-0 pr-3 mt-5 flex items-center"
                        >
                            {showPassword ? (
                                <EyeOffIcon className="h-5 w-5 text-gray-400" />
                            ) : (
                                <EyeIcon className="h-5 w-5 text-gray-400" />
                            )}
                        </button>
                    </div>
                    <div className='relative'>
                        <label htmlFor="confirm-password" className="block text-white text-sm font-medium">Confirmar Senha</label>
                        <input
                            id="confirm-password"
                            type={showPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirmar senha"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            autoComplete="new-password"
                        />
                         <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-1 right-0 pr-3 mt-5 flex items-center"
                            
                        >
                            {showPassword ? (
                                <EyeOffIcon className="h-5 w-5 text-gray-400" />
                            ) : (
                                <EyeIcon className="h-5 w-5 text-gray-400" />
                            )}
                        </button>
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-2 bg-green-500 text-black font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center"
                        disabled={loading}
                    >
                        {loading ? 'Redefinindo...' : 'Redefinir Senha'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
