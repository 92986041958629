import React from "react";
import axiosInstance from "../../services/axiosConfig";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Category.css";

const CategoryCard = ({ name, imageLink, onSelect, id, isSelected }) => (
  <div
    style={{ backgroundImage: `url(${encodeURI(imageLink)})` }}
    className={`category-card shadow-md overflow-hidden cursor-pointer ${
      isSelected ? "border-4 border-green-500 bg-green-100 " : ""
    }`}
    onClick={() => onSelect(id)}
  ></div>
);

const CategoryList = ({ onSelectGenre }) => {
  const [categories, setCategories] = React.useState([]);
  const [sliderRef, setSliderRef] = React.useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState(null);

  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/api/genres");
        setCategories(response.data);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCardClick = (id) => {
    setSelectedCategoryId(id);
    onSelectGenre(id);
    const index = categories.findIndex((category) => category.id === id);
    if (sliderRef) {
      sliderRef.slickGoTo(index);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 450,
    useCSS: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div className="category-list p-4">
      <h2>Gêneros</h2>
      <Slider ref={setSliderRef} {...settings}>
        {categories.map((category) => (
          <div key={category.id} className="p-2">
            <CategoryCard
              name={category.name}
              imageLink={category.image_link}
              onSelect={handleCardClick}
              id={category.id}
              isSelected={category.id === selectedCategoryId}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CategoryList;
