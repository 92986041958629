import React, { useEffect, useState } from 'react';
import CategoryList from '../../components/category/Category';
import ArtistList from '../../components/artist/Artist';
import SongList from '../../components/song/Song';
import axiosInstance from '../../services/axiosConfig';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './Listen.css';
import { FaRandom } from 'react-icons/fa';

export default function Listen() {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [artists, setArtists] = useState([]);
  const [currentArtistIndex, setCurrentArtistIndex] = useState(0);
  const [songs, setSongs] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(null);
  const [isShuffle, setIsShuffle] = useState(false);

  useEffect(() => {
    if (selectedGenre !== null) {
      const fetchArtists = async () => {
        try {
          const response = await axiosInstance.get(`/api/artists/${selectedGenre}`);
          setArtists(response.data);
          // setSelectedArtist(response.data[0]?.id); // Seleciona o index do artista automatico
          // setCurrentArtistIndex(0); // Seleciona o index do artista automatico
        } catch (error) {
          console.error('Erro ao buscar artistas:', error);
        }
      };

      fetchArtists();
    }
  }, [selectedGenre]);

  useEffect(() => {
    if (selectedArtist !== null) {
      setCurrentSongIndex(null);
    }
  }, [selectedArtist]);

  const handlePlaySong = (index) => {
    setCurrentSongIndex(index);
  };

  const handleNext = () => {
    if (isShuffle) {
      const randomIndex = Math.floor(Math.random() * songs.length);
      setCurrentSongIndex(randomIndex);
    } else {
      if (currentSongIndex + 1 < songs.length) {
        setCurrentSongIndex((prevIndex) => prevIndex + 1);
      } else {
        handleNextArtist();
      }
    }
  };

  const handlePrevious = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex - 1 + songs.length) % songs.length);
  };

  const handleSongEnd = () => {
    handleNext();
  };

  const handleNextArtist = () => {
    const nextArtistIndex = (currentArtistIndex + 1) % artists.length;
    setCurrentArtistIndex(nextArtistIndex);
    setSelectedArtist(artists[nextArtistIndex].id);
    
    setTimeout(() => {
      setCurrentSongIndex(1);
    }, 500); // 0,5segundos Pequeno atraso para garantir que as músicas sejam carregadas antes de tocar
  };

  return (
    <div>
      <CategoryList onSelectGenre={setSelectedGenre} />
      {selectedGenre && (
        <ArtistList
          genreId={selectedGenre}
          selectedArtist={selectedArtist} // Passa o artista selecionado
          onSelectArtist={(artistId) => {
            setSelectedArtist(artistId);
            const artistIndex = artists.findIndex((artist) => artist.id === artistId);
            setCurrentArtistIndex(artistIndex);
          }}
          setSongs={setSongs}
        />
      )}
      {selectedArtist && (
        <SongList
          artistId={selectedArtist}
          setSongs={setSongs}
          onPlaySong={handlePlaySong}
          currentSongIndex={currentSongIndex}
        />
      )}
      {currentSongIndex !== null && (
        <div className='player-component'>
          <div className="player-header">
            <h3 className='pl-4'>Ouvindo... {songs[currentSongIndex].name.replace('.mp3', '')}</h3>
            <button
              className={`shuffle-button ${isShuffle ? 'active' : ''}`}
              onClick={() => setIsShuffle(!isShuffle)}
            >
              <FaRandom />
            </button>
          </div>
          <AudioPlayer
            src={songs[currentSongIndex].song_link}
            autoPlay
            onPlay={() => console.log('Reproduzindo')}
            onClickPrevious={handlePrevious}
            onClickNext={handleNext}
            onEnded={handleSongEnd} 
            showSkipControls={true}
          />
        </div>
      )}
    </div>
  );
}
