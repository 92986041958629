import React, { useState } from 'react';
import axiosInstance from '../../services/axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/img/logo-2.png';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === '') {
            toast.error('O campo email é obrigatório');
            return;
        }

        setLoading(true);

        try {
            await axiosInstance.post('/auth/reset-password', { email });
            toast.success('Email de redefinição de senha enviado! Acesse o seu email!');
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error('Usuário não encontrado!');
                } else {
                    toast.error('Erro ao tentar enviar o email. Por favor, tente novamente.');
                }
            } else {
                toast.error('Erro de conexão. Por favor, tente novamente.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <ToastContainer theme="dark" />
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm login">
                <div className="logo-login">
                    <img src={logo} alt="logo" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-center">Redefinição de Senha</h2>
                <h3 className='text-white text-center text-sm'>Por favor, informe o seu e-mail cadastrado. Se ele estiver no sistema, enviaremos um link para redefinir a sua senha.</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-white text-sm font-medium">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Ex: joaosilva@gmail.com"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            autoComplete="email"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-2 bg-green-500 text-black font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center"
                        disabled={loading}
                    >
                        {loading ? 'Enviando...' : 'Enviar email de Redefinição'}
                    </button>
                    
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
