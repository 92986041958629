// FolderCard.js
import React from 'react';

const CardDownload = ({ title, imageUrl, link }) => {
  return (
    <div className="card-download max-w-sm overflow-hidden shadow-lg">
      <img className="w-full" src={imageUrl} alt={title} />
      <div className="px-6 py-4 text-center">
        <div className="font-bold text-xl mb-2 text-gray-800 dark:text-gray-200">
          {title}
        </div>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 hover:bg-green-800 text-black font-bold py-2 px-4 rounded"
        >
          BAIXAR AGORA
        </a>
      </div>
    </div>
  );
};

export default CardDownload;
