import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../services/axiosConfig';
import './Song.css';
import { SearchIcon } from '@heroicons/react/outline';

const SongCard = ({ name, onPlay, index, isSelected, songRef }) => (
  <div
    ref={songRef}
    className={`song-card shadow-md p-4 cursor-pointer ${isSelected ? 'selected border-2 border-green-500' : ''}`}
    onClick={() => onPlay(index)}
  >
    <h3 className="text-lg">{name.replace('.mp3', '')}</h3>
  </div>
);

const SongList = ({ artistId, setSongs, onPlaySong, currentSongIndex }) => {
  const [songs, setLocalSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [selectedSongIndex, setSelectedSongIndex] = useState(currentSongIndex);
  const [filterText, setFilterText] = useState('');
  const songListRef = useRef(null);
  const firstSongRef = useRef(null);

  useEffect(() => {
    if (artistId) {
      const fetchSongs = async () => {
        try {
          const response = await axiosInstance.get(`/api/songs/${artistId}`);
          const songsWithRandom = [
            { id: 'random', name: 'Tocar Aleatória' },
            ...response.data,
          ];
          setLocalSongs(songsWithRandom);
          setFilteredSongs(songsWithRandom);
          setSongs(songsWithRandom);
        } catch (error) {
          console.error('Erro ao buscar músicas:', error);
        }
      };

      fetchSongs();
    }
  }, [artistId, setSongs]);

  useEffect(() => {
    const filtered = songs.filter((song) =>
      song.name.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredSongs(filtered);
  }, [filterText, songs]);

  useEffect(() => {
    const scrollToFirstSong = () => {
      if (firstSongRef.current) {
        const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
        const elementPosition = firstSongRef.current.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - navbarHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    };

    scrollToFirstSong();
  }, [filteredSongs]);

  useEffect(() => {
    if (songListRef.current && selectedSongIndex !== null) {
      const songCardElements = songListRef.current.children;
      if (songCardElements[selectedSongIndex]) {
        songCardElements[selectedSongIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [selectedSongIndex]);

  useEffect(() => {
    setSelectedSongIndex(currentSongIndex);
  }, [currentSongIndex]);

  const handlePlaySong = (index) => {
    if (songs[index].id === 'random') {
      const randomIndex = Math.floor(Math.random() * (songs.length - 1)) + 1;
      setSelectedSongIndex(randomIndex);
      onPlaySong(randomIndex);
    } else {
      setSelectedSongIndex(index);
      onPlaySong(index);
    }
  };

  return (
    <div>
      <h2 className='text-center'>Músicas</h2>
      <div className="relative mb-4 mx-4">
        <input
          type="text"
          className="filter-input p-2 pl-10 w-full border-2 border-gray-300 rounded-lg"
          placeholder="Filtrar músicas..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <SearchIcon className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
      </div>
      <div className="song-list grid grid-cols-1 gap-6 p-4" ref={songListRef}>
        {filteredSongs.map((song, index) => (
          <SongCard
            key={song.id}
            name={song.name}
            onPlay={handlePlaySong}
            index={index}
            isSelected={index === selectedSongIndex}
            songRef={index === 0 ? firstSongRef : null}
          />
        ))}
      </div>
    </div>
  );
};

export default SongList;
