import "../../styles/download.css";
import CardDownload from "../../components/downloads/cardDownload";
import "../../styles/download.css";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axiosConfig";

export default function Downloads() {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const response = await axiosInstance.get('/api/downloads');
                setFolders(response.data);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    window.location.href = '/';
                } else {
                    console.error('Erro ao buscar pastas:', error);
                }
            }
        };

        fetchFolders();
    }, []);

    return (
        <div className="container-downloads mx-auto p-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {folders.map((folder, index) => (
                    <CardDownload
                        key={index}
                        imageUrl={folder.cover_image_link}
                        link={folder.download_link}
                    />
                ))}
            </div>
        </div>
    );
};